<template #content>
  <div>
    <MultiSelect
      name="groups"
      v-model="item.groups"
      :options="groups"
      optionLabel="name"
      optionValue="_id"
      style="width: 100%"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { GET_GROUPS, FETCH_GROUPS, GET_USERDATA } from '@/store/types';
import useI18n from '@/plugins/useI18n';

export default {
  props: {
    item: {
      type: Object
    }
  },
  setup() {
    const store = useStore();

    const groups = computed(() => {
      const groups = store.getters[GET_GROUPS];
      for (const k in groups) {
        groups[k].name =
          groups[k].name +
          ' (' +
          (groups[k].__contacts_id ? groups[k].__contacts_id.length : 0) +
          ')';
      }
      return groups;
    });

    const userData = computed(() => store.getters[GET_USERDATA]);

    store.dispatch(FETCH_GROUPS, userData.value.id);

    const { i18n } = useI18n();

    return {
      groups,
      i18n
    };
  }
};
</script>

