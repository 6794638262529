<template #content>
  <div>
    <div class="form-input">
      <label>{{ i18n.$t('Name') }}</label>
      <InputText type="text" name="name" v-model="item.name" />
    </div>
    <div class="form-input p-mt-3">
      <label>{{ i18n.$t('Description') }}</label>
      <QuillEditor
        toolbar="minimal"
        v-model:content="item.description"
        :content="item.description"
        contentType="html"
        @ready="quillReady"
      />
    </div>
    <AddDocument :item="item" />
  </div>
</template>

<script>
import Spinner from '@/components/UI/Spinner.vue';
import AddDocument from '@/components/Information/AddDocument.vue';
import useI18n from '@/plugins/useI18n';
import { QuillEditor } from '@vueup/vue-quill';
import { ref, watch } from 'vue';
export default {
  components: {
    Spinner,
    AddDocument,
    QuillEditor
  },
  props: {
    item: {
      type: Object
    }
  },
  setup(props) {
    const { i18n } = useI18n();
    let quill = null;
    function quillReady(e) {
      quill = e;
    }
    let assigned = false;
    watch(
      () => [props.item.description],
      () => {
        if (props.item.description != '' && !assigned) {
          quill.root.innerHTML = props.item.description;
          assigned = true;
        }
      }
    );
    return { i18n, quillReady };
  }
};
</script>
<style lang="scss">
.information-steps {
  .form-input {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 0.5rem;
    }
  }
  .radio-input {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    label {
      margin-left: 0.5rem;
      margin-bottom: 0;
      line-height: 100%;
    }
  }
}
</style>

