<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="p-mb-4"
      />
    </div>
    <div class="p-col-12">
      <h3 class="font-semibold">View information</h3>
      <Information />
    </div>
    <Button
      @click="$router.push({ path: '/information/list' })"
      label="Plain"
      class="p-button"
    >
      <span>{{ i18n.$t('Cancel') }}</span>
    </Button>
  </div>
</template>
<script>
import useI18n from '@/plugins/useI18n';
import Information from '@/components/Information/InformationView.vue';

export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Information list', to: '/information/list' }]
  }),
  components: {
    Information
  },
  setup() {
    const { i18n } = useI18n();
    return {
      i18n
    };
  }
};
</script>