<template #content>
  <div>
    <div class="form-input p-mt-3">
      <div class="eventSelect form-input">
        <label>{{ i18n.$t('Select Event') }}</label>
        <div>
          <MultiSelect
            name="events"
            v-model="item.events"
            :options="events"
            optionLabel="name"
            optionValue="_id"
            style="width: 80%"
          />
          <Button type="button" class="p-ml-2" @click="showSecondField = true">
            {{ i18n.$t('New Event') }}
          </Button>
        </div>
      </div>
      <div class="p-my-4">
        <transition-group name="p-messages" tag="div">
          <Message
            v-for="msg of message"
            :severity="msg.severity"
            :key="msg.content"
          >
            {{ msg.content }}
          </Message>
        </transition-group>
      </div>
      <div v-if="showSecondField">
        <div class="p-formgroup-inline">
          <div class="radio-input p-mr-4" v-for="el in items" :key="el.value">
            <RadioButton
              :id="el.value"
              name="action"
              :value="el.value"
              v-model="item._action"
            />
            <label :for="el.value">{{ el.label }}</label>
          </div>
        </div>
        <h4>{{ i18n.$t('Create Event') }}</h4>
        <div>
          <Form
            class="full-width-form"
            :event="event"
            :message="message"
            @save="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import Form from '@/components/Event/Form';
import EventModel from '@/models/event';
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import axios from '@/plugins/axios';
import {
  GET_ALL_EVENTS,
  FETCH_INFORMATION_EVENTS,
  GET_USERDATA
} from '@/store/types';

export default {
  props: {
    item: {
      type: Object
    }
  },
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/profile/myprofile' },
    breadcrumbItems: [
      { label: 'My Task', to: '/events/tasklist?_type=Task' },
      { label: 'My Rdv', to: '/events/rdvlist?_type=Rdv' },
      { label: 'My Event', to: '/events/eventlist?_type=Event' }
    ]
  }),

  components: { Form },
  setup(props, { emit }) {
    const store = useStore();
    const events = computed(() => store.getters[GET_ALL_EVENTS]);

    const userData = computed(() => store.getters[GET_USERDATA]);

    store.dispatch(FETCH_INFORMATION_EVENTS, {
      userId: userData.value.id
    });

    const showSecondField = ref(false);
    const items = [
      { value: 'CreateTask', label: 'Create Task' },
      { value: 'CreateEvent', label: 'Create Event' },
      { value: 'CreateRDV', label: 'Create RDV' }
    ];
    const { i18n } = useI18n();
    const event = reactive(new EventModel({}));
    const message = ref();

    async function save(eventData) {
      //console.log(eventData, 'events s-2');
      try {
        const res = await axios.post('/event', event);
        message.value = [
          { severity: 'success', content: 'Saved successfully' }
        ];
        showSecondField.value = false;
        await store.dispatch(FETCH_INFORMATION_EVENTS, {
          userId: userData.value.id
        });
        //console.log(res);
        props.item.events.push(res.data.data.items[0]._id);
        emit('event-saved');
      } catch (e) {
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong!' }
        ];
        console.log(e);
      }
    }
    watch(
      () => [props.item._action],
      () => {
        switch (props.item._action) {
          case 'CreateRDV':
            event._type = 'Rdv';
            break;
          case 'CreateEvent':
            event._type = 'Event';
            break;
          case 'CreateTask':
          default:
            props.item._action = 'CreateTask';
            event._type = 'Task';
        }
      }
    );
    return {
      items,
      i18n,
      event,
      save,
      showSecondField,
      store,
      events,
      userData,
      message
    };
  }
};
</script>
<style scoped lang="scss">
.eventSelect {
  margin-bottom: 20px;
}
.information-steps {
  .form-input {
    display: flex;
    flex-direction: column;
    label {
      margin-bottom: 0.5rem;
    }
  }
  .radio-input {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    label {
      margin-left: 0.5rem;
      margin-bottom: 0;
      line-height: 100%;
    }
  }
}
</style>