<template #content>
  <div class="information-add-step5">
    <div class="p-col-6">
      <div class="form-input p-mt-3">
        <label>{{i18n.$t('Name')}}: </label>
        {{ item.name }}
      </div>
      <div class="form-input p-mt-3">
        <label>{{i18n.$t('Description')}}: </label>
        {{ item.description }}
      </div>
      <div class="form-input p-mt-3">
        <label>{{i18n.$t('Document')}}: </label>
        <span>{{ displayDocumentName(item.document_id) }}</span>
      </div>
      <div class="form-input p-mt-3">
        <label>{{i18n.$t('Action')}}: </label>
        {{ item._action }}
      </div>
      <div class="form-input p-mt-3">
        <label>{{i18n.$t('Events')}}: </label>
        <span class="p-mr-3" v-for="event in item.events" :key="event">
          {{ displayEventName(event) }}
        </span>
      </div>
      <div class="form-input p-mt-3">
        <label>{{i18n.$t('Groups')}}: </label>
        <span class="p-mr-3" v-for="group in item.groups" :key="group">
          {{ displayGroupName(group) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { GET_GROUPS, GET_DOCUMENTS, GET_ALL_EVENTS } from '@/store/types';
import useI18n from '@/plugins/useI18n';

export default {
  components: {},
  props: {
    item: {
      type: Object
    }
  },
  setup(props) {    
    const store = useStore();

    const { i18n } = useI18n();;


    const groups = computed(() => store.getters[GET_GROUPS]);
    const documents = computed(() => store.getters[GET_DOCUMENTS]);
    const events = computed(() => store.getters[GET_ALL_EVENTS]);

    function displayGroupName(groupId) {
      const group = _.find(groups.value, function (o) {
        return o._id === groupId;
      });
      return group ? group.name : 'unknown';
    }

    function displayDocumentName(documentId) {
      const document = _.find(documents.value, function (o) {
        return o._id === documentId;
      });
      return document ? document.name : 'unknown';
    }

    function displayEventName(eventId) {
      const event = _.find(events.value, function (o) {
        return o._id === eventId;
      });
      return event ? event.name : 'unknown';
    }

    return {
      displayGroupName,
      displayDocumentName,
      displayEventName,
      groups,
      documents,
      events,
      i18n
    };
  }
};
</script>
<style lang="scss">
.information-add-step5.information-steps {
  .form-input {
    flex-direction: row;
    label {
      margin-right: 0.5rem;
    }
  }
}
</style>
